import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { KEY_INFO_USER } from "../../../constants/app-const";
import LogoBYT from "../../../assets/logo_byt.png";
import AliveIcon from "../../../components/SvgIcons/AliveIcon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocation } from "../../../stores/location/location.action";
import { IAppState } from "../../../stores/state";
import { convertToAutocomplete } from "../../../helper/convertToAutocomplete";
import { SelectBase } from "../../../components/Select";

const LoginClient = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resultGetLocation = useSelector((state: IAppState) => state.locations);
  const listLocations = resultGetLocation?.data?.location;

  const onSubmit = (value: any) => {
    localStorage.setItem(
      KEY_INFO_USER,
      JSON.stringify({ token: "dasdfasfa", role: "agency" })
    );
    navigate(`/send-report?location_id=${value.province}`);
  };

  useEffect(() => {
    dispatch(getLocation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-wrap w-full">
      <div className="flex w-full min-h-screen justify-center items-center flex-col sm:flex-col md:flex-row">
        <div className="xl:w-2/3 lg:w-2/3 md:w-2/3 bg-background-login block h-full bg-cover bg-center w-full relative">
          <p className="w-20 mt-5 ml-4">
            <img className="w-full h-full" src={LogoBYT} alt="logo_byt" />
          </p>
          <div className="flex flex-col md:flex-row gap-1 absolute bottom-0 py-2 px-6 items-center w-full">
            <p className="text-white text-sm">© Bản quyền thuộc Bộ Y tế</p>
            <div className="flex flex-col lg:flex-row items-center mx-12">
              <p className="text-white text-sm mr-2">Hỗ trợ xây dựng bởi</p>
              <AliveIcon />
            </div>
            <p className="text-white text-sm">
              Ảnh: Giacomo Pirozzi | Alive & Thrive
            </p>
          </div>
        </div>
        <div className="flex justify-center pt-8 px-6 my-auto md:pt-0 xl:w-1/3 lg:w-1/3 md:w-1/3 w-full">
          <div className="sm:w-auto md:w-96">
            <div className="mb-8">
              <p className="text-center font-bold text-3xl mb-2">
                BÁO CÁO CHỈ SỐ
              </p>
              <p className="text-center font-semibold text-2xl text-primary">
                Chương trình mục tiêu quốc gia dân tộc thiểu số
              </p>
            </div>
            {Array.isArray(listLocations) && listLocations?.length > 0 ? (
              <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                  <Controller
                    control={control}
                    name="province"
                    render={({ field: { onChange, value } }) => (
                      <SelectBase
                        options={convertToAutocomplete(
                          // listLocations,
                          listLocations.sort((a, b) =>
                            a?.province.localeCompare(b?.province)
                          ),
                          "province"
                        )}
                        classNameContainer="w-full"
                        className="w-full flex justify-between bg-slate-100"
                        placement="right"
                        label="Lựa chọn đơn vị báo cáo"
                        onChange={onChange}
                        value={value}
                        error={errors?.province?.message}
                      />
                    )}
                    rules={{
                      required: "Trường này là bắt buộc.",
                    }}
                  />
                </div>
                <Button id="continue" title="Tiếp tục" />
              </form>
            ) : null}
            <div className="pt-12 pb-12 text-center">
              <div>
                Bạn cần trợ giúp?
                <a
                  href="mailto:thanh@unopixel.io"
                  className="ml-1 font-semibold underline cursor-pointer w-fit mr-auto"
                >
                  Liên hệ với chúng tôi.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginClient;
